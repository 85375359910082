import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu" ];

  toggle() {
    const mobileMenu = this.menuTarget;
    const contentId = 'mobile-menu-marketing';
    const isOpen = mobileMenu.classList.contains("is-active");

    if (isOpen){
      this.applyCloseBehaviour(mobileMenu, contentId);
    } else {
      this.applyOpenBehaviour(mobileMenu, contentId);
    }

    document.querySelector('body').classList.toggle("freeze-scroll");
  }

  getMenuById(menuId) {
    return document.getElementById(menuId);
  }

  applyOpenBehaviour(mobileMenu, mobileMenuId){
    mobileMenu.classList.add('is-active');
    mobileMenu.style.maxHeight = mobileMenu.scrollHeight + "px";
    const menu = this.getMenuById(mobileMenuId);

    setTimeout(function () {
      menu.style.maxHeight = 'unset';
      menu.style.overflow = "auto";
    }, 400);
  }

  applyCloseBehaviour(mobileMenu, mobileMenuId){
    mobileMenu.style.maxHeight = mobileMenu.scrollHeight + "px";
    mobileMenu.style.overflow = "hidden";
    const menu = this.getMenuById(mobileMenuId);

    setTimeout(function () {
      menu.style.maxHeight = null;
    }, 5);

    setTimeout(function () {
      menu.classList.remove('is-active');
    }, 400);
  }
}
