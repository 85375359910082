import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "successNotification", "errorMessage" ]

  onPostSuccess(e) {
    this.formTarget.classList.add('d-none');
    this.successNotificationTarget.classList.remove('d-none');
  }

  onPostError(e) {
    this.errorMessageTarget.classList.add('error');
    this.errorMessageTarget.innerText = e.detail[0].msg;
  }
}
