import { Controller } from "@hotwired/stimulus";

// NOTE: NO LONGER SUPPORTED 2nd Jan 2024

// Assist with setting/loading state, and controlling the LiveChat widget. To be
// used alongside where `app/views/shared/_live_chat.html.erb` has been rendered.
//
// NOTE: Widget is loaded asynchronously from the page <head> (via turbolinks),
// so it is a challenge to reliably use Stimulus controller variable states,
// targets etc.
//
// e.g. can't rely on `this.foo = true` to accurately track widget state
//

// Use `localStorage` to keep track of state
const SHOW_CHAT_KEY = "showChat";

// LiveChat widget methods
// https://developers.livechat.com/docs/extending-chat-widget/javascript-api/
const MAXIMIZE = "maximize";
const MINIMIZE = "minimize";
const HIDE = "hide";

// Global identifier for chatbox button
const CHATBOX_BUTTON = ".chatbox-button";
const WIDGET_ID = "chat-widget-container";
const DISMISS_BUTTON_ID = "chat-widget-dismiss-button";
const DISMISS_BUTTON_CLASS = "chat-widget-dismiss-button";

export default class extends Controller {
  loadState() {
    this._enableChatButtons();
    this._loadWidgetVisibility();
    this._allowNewAgentMessageToShowWidget();
    this._attachDismissButton();
  }

  toggle() {
    if (this._isShown()) {
      this._hide();
    } else {
      this._maximize();
    }
  }

  _isShown() {
    const showChat = window.localStorage.getItem(SHOW_CHAT_KEY);

    // set up localStorage if it doesn't already exist
    if (showChat === null) {
      window.localStorage.setItem(SHOW_CHAT_KEY, "false");
      return false;
    }

    // localStorage only stores Strings; parse result to work with Booleans instead
    return JSON.parse(showChat);
  }

  _enableChatButtons() {
    const chatButtons = document.querySelectorAll(CHATBOX_BUTTON);
    chatButtons.forEach((chatButton) => {
      chatButton.disabled = false;
    });
  }

  _allowNewAgentMessageToShowWidget() {
    const showWidget = (event) => {
      if (!this._isShown() && event.author.type === "agent") {
        this._maximize();
      }
    };

    LiveChatWidget.on("new_event", showWidget);
  }

  _attachDismissButton() {
    const widgetHandle = document.getElementById(WIDGET_ID);
    const dismissBtnHtml = `<div class="${DISMISS_BUTTON_CLASS}" id="${DISMISS_BUTTON_ID}" title="hide chat window">x</div>`;
    widgetHandle.insertAdjacentHTML("afterbegin", dismissBtnHtml);
    document
      .getElementById(DISMISS_BUTTON_ID)
      .addEventListener("click", (e) => {
        e.preventDefault();
        this._hide();
      });
  }

  _loadWidgetVisibility() {
    if (this._isShown()) {
      LiveChatWidget.call(MINIMIZE); // show less obtrusive version after navigating
    } else {
      LiveChatWidget.call(HIDE);
    }
  }

  _hide() {
    window.localStorage.setItem(SHOW_CHAT_KEY, "false");
    LiveChatWidget.call(HIDE);
  }

  _maximize() {
    window.localStorage.setItem(SHOW_CHAT_KEY, "true");
    LiveChatWidget.call(MAXIMIZE);
  }
}
