import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  initialize() {
    this.itemTargets.forEach((itemTarget, i) => {
      this._show(itemTarget, false);
    });
  }

  select(e) {
    const current_target = e.currentTarget;
    e.preventDefault();
    this.itemTargets.forEach((item_target, i) => {
      if (item_target.dataset.control == current_target.dataset.control) {
        item_target.classList.add("is-active");
        this._show(item_target);
      } else {
        item_target.classList.remove("is-active");
        this._show(item_target);
      }
    });
  }

  _show(itemTarget, withAnimation = true) {
    const isOpen = itemTarget.classList.contains("is-active");
    const contentId = itemTarget.dataset.control;
    const content = document.querySelector(`#${contentId}`);

    if (isOpen) {
      this._applyOpenBehaviour(content, contentId, withAnimation);
      return;
    }

    this._applyCloseBehaviour(content, contentId);
  }

  _applyOpenBehaviour(content, contentId, withAnimation) {
    content.style.display = "block";
    const timeout = withAnimation ? 10 : 0;
    setTimeout(function () {
      document.getElementById(contentId).classList.add("is-active");
    }, timeout);
  }

  _applyCloseBehaviour(content, contentId) {
    setTimeout(function () {
      document.getElementById(contentId).classList.remove("is-active");
    }, 10);
    content.style.display = "none";
  }
}
