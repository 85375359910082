import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import attachHoneybadger from '../config/honeybadger';

// Start Stimulus application
const application = Application.start();
attachHoneybadger(application);

// Load and set up remaining Stimulus configurations
const context = require.context(
  'marketing_controllers',
  true,
  /_controller\.js$/,
);
application.load(definitionsFromContext(context));
