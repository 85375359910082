import * as Honeybadger from "@honeybadger-io/js";

function attachHoneybadger(stimulusApplication) {
  if (process.env.RAILS_ENV === 'test') {
    return;
  }

  // Honeybadger - configuration (step 1 of 2)
  if (!process.env.HONEYBADGER_API_KEY) {
    console.warn(
      "Honeybadger API KEY not set! Frontend exception monitoring is not active.",
    );
  }

  // Attaching Honeybadger to our Stimulus controllers in this way makes our
  // build "stateful". So using the "pipeline promotion" functionality in Heroku
  // will not set these variables correctly in the new environment.
  //
  // i.e. promoting staging -> production will have staging credentials baked
  // into our JS payload on production.
  //
  // So setting `environment` and `revision` is difficult to do without switching
  // to Git-based deployment.
  //
  // https://devcenter.heroku.com/articles/pipelines#design-considerations
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    developmentEnvironments: ["development", "test"],
    // environment: process.env.RAILS_ENV,
    // revision: process.env.GIT_COMMIT,
  });

  // Honeybadger - set up error handler for Stimulus (step 2 of 2)
  stimulusApplication.handleError = (error, message, detail) => {
    console.warn(message, detail);
    console.error(error);
    Honeybadger.notify(error);
  };
}

export default attachHoneybadger;
