import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "imageContainer" ];

  toggle() {
    const imageContainer = this.imageContainerTarget;

    if( imageContainer.classList.contains('is-active') ){
      this._closeModal();
    }else{
      this._openModal();
    }
  }

  _openModal() {
    const imageContainer = this.imageContainerTarget;

    document.documentElement.classList.add("freeze-scroll");
    imageContainer.style.display = 'block';

    setTimeout(function () {
      imageContainer.classList.add('is-active');
    }, 10, imageContainer);
  }

  _closeModal() {
    const imageContainer = this.imageContainerTarget;

    document.documentElement.classList.remove("freeze-scroll");
    imageContainer.classList.remove('is-active');

    setTimeout(function () {
      imageContainer.style.display = 'none';
    }, 800, imageContainer);
  }
}
